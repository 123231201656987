$size-burger-icon: 22;

%burger-icon {
  display: block;
  background-color: #{$secondary};
  height: 2px;
}

%burger-icon-after-before {
  @extend %burger-icon;
  content: '';
  position: absolute;
}

.burger {
  display: none;
  align-self: flex-start;
  align-items: center;
  justify-content: center;
  margin: 0;
  height: #{$menu-size-height-responsive+$padding-menu}px;
  @include media-breakpoint-up(md) {
    height: #{$menu-size-height + ($padding-menu*2)}px;
  }
  width: 40px;
  position: absolute;
  right: #{$gutter}px;
  top: 0;
  z-index: 51;
  @include media-breakpoint-down(xl) {
    display: flex;
  }
  @include media-breakpoint-down(sm) {
    right: #{$gutter-responsive}px;
  }
  &__button {
    position: relative;
    @include size(#{$size-burger-icon}px);
    padding: 0;
    border: 0;
    &:before {
      @extend %burger-icon-after-before;
      top: 2px;
      width: #{$size-burger-icon}px;

      transition-property: top, transform, background-color;
      transition-duration: 0.2s;
      transition-delay: 0.3s, 0s;
    }
    &:after {
      @extend %burger-icon-after-before;
      bottom: 2px;
      width: #{$size-burger-icon/2}px;

      transition-property: bottom, transform, width, background-color;
      transition-duration: 0.2s;
      transition-delay: 0.3s, 0s, 0s;
    }
    &:hover {
      &:after {
        width: #{$size-burger-icon}px;
      }
      .burger__line {
        width: #{$size-burger-icon}px;
      }
    }
  }
  &__line {
    @extend %burger-icon;
    width: #{($size-burger-icon/4)*3}px;
    opacity: 1;
    transition-property: width, opacity;
    transition-duration: 0.3s;
  }
}

.header--responsive {
  .burger__button {
    position: relative;
    flex-flow: initial;
    &:before {
      width: #{$size-burger-icon}px;
      transform: rotate(-45deg);
      top: #{($size-burger-icon/2)-1}px;
      transition-delay: 0s, 0.3s;
      background-color: white;
    }
    &:after {
      width: #{$size-burger-icon}px;
      background-color: white;
      transform: rotate(45deg);
      bottom: #{($size-burger-icon/2)-1}px;
      transition-delay: 0s, 0.3s;
    }
    .burger__line {
      opacity: 0;
      background-color: white;
      transition: opacity 0s;
    }
  }
}
