.survey {
  background-color: #FFE2E2;
  //display: none;
  //&.open {
  //  display: block;
  //}
  &__text {
    color: $primary;
    display: block;
    margin-bottom: 10px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      margin-right: 50px;
    }
  }
  &__btn {
    @extend .button--primary;
    &--close {
      border: 0;
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 10px;
      @include media-breakpoint-up(lg) {
        position: initial;
        margin-left: auto;
      }
    }
  }
}