.pagination {
  &__item {
    &:not(&:last-child) {
      margin-right: 12px;
    }
    &--active {
      @extend .pagination__item;
      color: white;
      background-color: $secondary;
    }
  }
  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33px;
    height: 33px;
    border-radius: 33px;
    color: $primary;
    border: 1px solid white;
    &:hover {
      border: 1px solid $primary;
    }
    &--disabled {
      @extend .pagination__link;
      align-items: flex-end;
    }
    &--active {
      @extend .pagination__link;
      color: white;
      background-color: $secondary;
      &:hover {
        color: $secondary;
        border: 1px solid $secondary;
        background-color: white;
      }
    }
  }
}