.nav {
  width: 100%;
  padding-right: 0;
  display: none;
  @include media-breakpoint-up(xl) {
    display: flex;
    padding-left: 30px;
  }
  @include media-breakpoint-up(xxl) {
    padding-left: 80px;
  }
  &__list {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    list-style: none;
    padding: 0;
    gap: 15px;
    @include media-breakpoint-up(xl) {
        gap: 28px;
    }
    @include media-breakpoint-down(md) {
      justify-content: center;
    }
  }
  &__link {
    font: {
      size: calculate-rem(14);
      weight: 400;
    }
    white-space: nowrap;
  }
  &__item {
    display: none;
    @include media-breakpoint-up(md) {
      display: block;
    }
    &--settings {
      @extend .nav__item;
      display: flex;
      max-width: 200px;
    }
    &--search {
      @extend .nav__item;

      @include media-breakpoint-up(lg) {
        width: 50%;
      }
    }
  }
  &__buttons {
    padding-right: 60px;
    display: none;
    justify-content: center;
    align-items: center;
    gap: 10px;
    @include media-breakpoint-up(md) {
      display: flex;
      gap: 16px;
    }
    @include media-breakpoint-up(xl) {
        padding-right: 0;
    }
  }
}