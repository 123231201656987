$menuItems: 4;

.header {
  width: 100%;
  z-index: 500;
  padding: #{$padding-menu}px 0;
  left: 0;
  height: #{$menu-size-height-responsive+($padding-menu)}px;
  display: flex;
  align-items: center;
  @include media-breakpoint-up(md) {
    height: #{$menu-size-height+($padding-menu*2)}px;
  }
  @include media-breakpoint-down(sm) {
    padding: #{$padding-menu/2}px 0;
  }
  &__container {
    padding: 0 #{$gutter-responsive}px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    @include media-breakpoint-up(md) {
      padding: 0 #{$gutter}px;
    }
  }
  &, &--initial {
    position: absolute;
    top: 0;
  }
  &--transition {
    position: fixed;
    top: -#{$menu-size-height+($padding-menu*2)}px !important;
    transition: top .5s;
    @include media-breakpoint-down(xs) {
      top: -#{$menu-size-height-responsive+$padding-menu}px !important;
    }
  }
  &--transitionDown {
    position: fixed;
    top: -#{$menu-size-height+($padding-menu*2)}px !important;
    @include media-breakpoint-down(xs) {
      top: -#{$menu-size-height-responsive+$padding-menu}px !important;
    }
  }
  &--sticky {
    position: fixed;
    top: 0;
    transition: top .5s;
    height: #{$menu-size-height+$padding-menu}px;
    background-color: white;
    padding: #{$padding-menu/2}px 0;
    .header__logo {
      height: 50px;
    }
    .burger {
      @include media-breakpoint-up(md) {
        height: #{$menu-size-height + ($padding-menu)}px;
      }
    }
    @include media-breakpoint-down(sm) {
      height: #{$menu-size-height-responsive+$padding-menu}px;
    }
  }
  &--transition {
    background-color: white;
    padding: #{$padding-menu/2}px 0;
    &Down {
      padding: 20px 0;
    }
    .header__logo {
      height: 50px;
    }
  }
  &__container-logo {
    display: flex;
    align-items: center;
  }
}

.header--responsive {
  .responsiveNav {
    height: 250px;
    transition-property: height;
    transition-duration: 300ms;
    border-bottom: 1px solid black;
  }
  .socialNetwork__link, .informations__link {
    transform: translateY(0%);
    opacity: 1;
    transition-property: transform;
    transition-duration: .3s;
    transition-timing-function: ease-in;
    transition-delay: #{($menuItems * 100) + 100}ms;
    @include media-breakpoint-down(md) {
      opacity: 1;
    }
    &:hover {
      opacity: 1;
      color: white;
    }
  }
  .languages__listItem {
    transform: translateY(0%);
    transition-property: transform;
    transition-duration: .2s;
    transition-timing-function: ease-in;
    transition-delay: 300ms;
  }
}