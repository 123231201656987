// Colors
// -----------------------------
$secondary-light: #FFE2E2;
$tertiary: #F9E6E6;
$quartenary: #262626;
$gray-light: #F8F8F8;
//$gray-light: #999999;

// Menu height
// -----------------------------
$menu-size-height: 78;
$menuMarginBottom: 49;
$menu-size-height-responsive: 47;
$padding-menu: 20;
$gutter: 70;
$gutter-responsive: 15;

// Nav
// -----------------------------
$languages: false;
$language-top: false;
$menuItems: 5;
$menuItems-responsive: 12;
/*
  1: nav (md >) + nav-responsive (< md)
  2: nav + nav-responsive all size
  3: nav-responsive
  4: header fixed TODO not effective yet
*/
$type-of-nav: 1;
/*
  1: big menu from right. Item flex-column
  2: big menu from right. Item flex-row
  3: big menu opacity / visibility. Item from right
*/
$type-of-responsive-nav: 3;

// ScrollBar
// -----------------------------
$scroll-bar-width: 10px;
$scroll-bar-track: #fafafa;
$scroll-bar-thumb: #666666;

// Font
// -----------------------------
$font: 'Hind';
$font-secondary: 'P22Mackinac';

$font-color: $primary !default;
$link-color: $primary;
$background-color: white;
$font-size: 1rem !default;

// TODO build list of font-sizes
$font-sizes: () !default;
$font-sizes: map-merge(
                (
                        10: calculate-rem(10),
                        12: calculate-rem(12),
                        14: calculate-rem(14),
                        16: calculate-rem(16),
                        18: calculate-rem(18),
                        20: calculate-rem(20),
                        22: calculate-rem(22),
                        24: calculate-rem(24),
                        32: calculate-rem(32),
                        42: calculate-rem(42),
                ),
                $font-sizes
);

$font-face-include: () !default;
$font-face-include: map-merge(
                (
                        "light": 300,
                        "regular": 400,
                        "medium": 500,
                        "semibold": 600,
                        "bold": 700
                ),
                $font-face-include
);


$font-face-include-secondary: () !default;
$font-face-include-secondary: map-merge(
                (
                        "medium": 500
                ),
                $font-face-include-secondary
);

// Titles
// (fs, lh, responsive fs, responsive lh, fw)
// -----------------------------
$titles: () !default;
$titles: map-merge(
                (
                        "1": (40, 30, 40, 42, 400),
                        "2": (26, 33, 22, 33, 400),
                        "3": (32, 30, 22, 33, 400),
                        "4": (20, 30, 22, 33, 400),
                        "5": (18, 23, 22, 33, 400),
                        "6": (28, 32, 22, 33, 400),
                ),
                $titles
);