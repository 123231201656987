.upload {
  &__drag-drop {
    border: 1px dashed $secondary;
    height: 230px;
  }
  &__list {
    max-height: 200px;
    overflow-y: auto;
  }
  &__text {
    background-image: url("/static/img/v2/components/upload.svg");
    background-position: center top;
    background-size: 75px 51px;
    background-repeat: no-repeat;
    line-height: calculate-rem(27);
    padding-top: 70px;
  }
  &__input {
    position: absolute;
    top: -20px;
    left: -20px;
    transform: scale(10);
    transform-origin: top left;
    opacity: 0;
    cursor: pointer;
  }
  &__file {
    background-color: $light;
  }
  &__hover {
    border-style: solid;
  }
  &__name {
    display: block;
    max-width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 24px;
    &-container {
      width: fit-content;
      max-width: 100%;
    }
  }
  &__loading {
    stroke-dasharray: 187;
    stroke-dashoffset: 0;
    animation: load 2s ease-in-out infinite;
    &-complete {
      stroke: #13F200;
    }
  }
  &__check {
    opacity: 0;
    &-complete {
      opacity: 1;
      stroke-dasharray: 1000;
      stroke-dashoffset: 1000;
      animation: draw 8s ease-out forwards;
    }
  }
  &__fail {
    opacity: 0;
    &--left, &--right {
      transform: rotate(0deg);
      transform-origin: center;
      transition: transform 300ms ease-in;
    }
    &-complete {
      opacity: 1;
      .upload__fail--left {
        transform: rotate(45deg);
      }
      .upload__fail--right {
        transform: rotate(-45deg);
      }
    }
  }
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes load {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.74;
  }
  100% {
    stroke-dashoffset: 187;
  }
}