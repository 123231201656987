.footer {
  &__logo {
    width: 100% !important;
    max-width: 220px;
    height: auto;
    margin-bottom: 30px;
    @include media-breakpoint-down(sm) {
        margin: 0 auto 30px auto;
    }
  }
  &__icon {
    display: inline-block;
    background-size: 30px 30px;
    background-repeat: no-repeat;
    font-size: 1px;
    width: 30px;
    height: 30px;
    text-indent: -5000px;
    &--facebook {
      @extend .footer__icon;
      background-image: url("/static/img/v2/components/facebook.svg");
    }
    &--youtube {
      @extend .footer__icon;
      background-image: url("/static/img/v2/components/youtube.svg");
    }
    &--linkedin {
      @extend .footer__icon;
      background-image: url("/static/img/v2/components/linkedin.svg");
    }
    &--instagram {
      @extend .footer__icon;
      background-image: url("/static/img/v2/components/instagram.svg");
    }
  }
  &__privacy {
    border-top: 1px solid white;
  }
}