.hero {
  //height: calc(100vh - #{($padding-menu)+$menu-size-height-responsive}px);
  min-height: 600px;
  position: relative;
  @include media-breakpoint-up(md) {
    height: calc(100vh - #{($padding-menu*2)+$menu-size-height}px);
  }
  &__container {
    height: 100%;
  }
  &__row {
    height: 100%;
    position: relative;
  }
  &__title {
    font-family: $font-secondary;
    font-size: calculate-rem(55);
    line-height: calculate-rem(56);
    font-weight: 500;
    color: $primary;
    @include media-breakpoint-up(xl) {
      font-size: calculate-rem(80);
      line-height: calculate-rem(80);
    }
  }
  &__schedule {
    position: relative;
    width: fit-content;
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: $primary;
      border-radius: 20px;
      top: 5px;
      left: 5px;
      z-index: 1;
    }
  }
  &__paragraph {
    line-height: calculate-rem(20);
    z-index: 2;
    padding: 19px 31px 23px 26px;
  }
  &__image {
    @include media-breakpoint-up(md) {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  &__contact {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    @include media-breakpoint-up(md) {
      display: block;
      transform: rotate(-90deg) translateX(-50%);
      position: absolute;
      top: 50%;
      left: 10px;
      transform-origin: left top;
      margin-top: 0;
    }
    @include media-breakpoint-up(lg) {
      left: 30px;
    }
    &-item {

    }
    &-icon {
      transform: rotate(90deg) translateY(10px);
      transform-origin: center;
    }
  }
  &__element {
    &--left {
      display: block;
      position: absolute;
      background-color: rgba(255, 217, 217, 0.5);
      width: 80%;
      height: 100%;
      transform: rotate(-8.7deg);
      top: -26%;
      left: -5%;
      z-index: -1;
    }
    &--right {
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: -1;
    }
  }
}