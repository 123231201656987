.button {
  border-width: 1px;
  border-style: solid;
  border-radius: 30px;
  font-size: calculate-rem(14);
  font-weight: 600;
  padding: 14px 32px 12px 32px;
  transition-property: color, background-color;
  transition-duration: 300ms;
  transition-timing-function: ease-in;
  &.disabled {
    opacity: 0.5;
  }
  &--primary {
    @extend .button;
    background-color: $secondary;
    color: white;
    border-color: $secondary;
    &:hover {
      color: $secondary;
      background-color: white;
    }
  }
  &--secondary {
    @extend .button;
    color: $secondary;
    background-color: white;
    border-color: $secondary;
    &:hover {
      background-color: $secondary;
      color: white;
    }
  }
  &--list {
    background-color: $primary;
    color: white;
    border: 1px solid $primary;
    border-radius: 20px;
    font-size: calculate-rem(13);
    padding: 9px 13px 9px 17px;
  }
  &--shop {
    @extend .button;
    background-color: $secondary-light;
    border-color: $secondary-light;
    color: $primary;
    &-badge {
      top: 4px;
      right: 0;
    }
    &-connected {
      @extend .button--shop;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      width: 48px;
      height: 48px;
    }
  }
  &--add {
    background-color: $secondary;
    border-color: $secondary;
    color: white;
    border-radius: 20px;
    font-size: calculate-rem(13);
    padding: 9px 13px 9px 17px;
    transition-property: background-color, color;
    transition-duration: 300ms;
    transition-timing-function: ease-in;
    &:hover {
      background-color: white;
      color: $secondary;
    }
  }
  &--search {
    &:hover {
      background-color: #e8e8e8 !important;
    }
  }
  &--form {
    padding: 4px 12px;
    appearance: none;
    font-size: calculate-rem(12);
    transition-property: color, background-color;
    transition-duration: 300ms;
    transition-timing-function: ease-in;
    &--primary {
      @extend .button--form;
      background-color: $primary;
      border: 1px solid $primary;
      color: white;
      &:hover {
        background-color: white;
        color: $primary;
      }
    }
    &--secondary {
      @extend .button--form;
      background-color: white;
      border: 1px solid $primary;
      color: $primary;
      &:hover {
        background-color: $primary;
        color: white;
      }
    }
  }
}

.btn-close {
  padding: 0.75rem;
}