.homepage {
  &__cloud {
    height: 110px;
  }
  &__syllabus {
    &-list {
      @include media-breakpoint-up(lg) {
        position: absolute;
        transform: translateY(-50%);
      }
    }
  }
  &__delivery {
    &-image {
      width: 400px;
      max-width: 100%;
    }
    &-num {
      width: 50px;
      height: 50px;
    }
    &-element {
      &--top-left {
        position: absolute;
        left: -5%;
        bottom: -100px;
        width: 110%;
        display: block;
        height: 200px;
        background-color: $light;
        z-index: -1;
        transform: rotate(5.91deg);
      }
      &--top-right {
        position: absolute;
        right: 50px;
        bottom: -250px;
        width: 50%;
        display: block;
        height: 350px;
        background-color: #FFE1E1;
        z-index: -1;
        transform: rotate(-19.82deg);
      }
    }
  }
}