.icon {
  &--catalogue {
    width: 148px;
    min-width: 148px;
    height: 148px;
  }
  &--next {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    width: 30px;
    height: 30px;
    border-radius: 30px;
  }
}