// You can't modify this file !
// ------------------------------

a {
  color: $link-color;
  text-decoration: none;
  &:hover {
    color: $link-color;
    text-decoration: none;
  }
  &:visited {
    text-decoration: none;
  }
}

p {
  margin: 0;
}

button {
  background: none;
  cursor: pointer;
  box-sizing: content-box;
  border: 1px solid black;
  &:focus {
    outline: none;
  }
}

.img-100 {
  max-width: 100%;
  width: 100%;
}