.form {
  &__textarea {
    resize: none;
    height: 140px;
  }
  &__select {
    max-width: 160px;
    @include media-breakpoint-up(xxl) {
        max-width: 200px;
    }
  }
  &__search {

  }
  &__spinner {
    width: 15px;
    height: 15px;
  }
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  background-position: calc(100% - 12px) center;
}