.filters {
  @include media-breakpoint-down(lg) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 500;
    max-width: 100%;
    justify-content: center;
    display: none;
    &--open {
      display: flex;
      .filters__submit {
        display: block;
      }
      .filters__reset {
        display: none;
      }
      .filters__close {
        display: flex;
      }
      .filters__backdrop {
        display: block;
      }
    }
  }
  height: fit-content;
  &__backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: 499;
    display: none;
  }
  &__close {
    display: none;
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    justify-content: center;
    align-items: center;
    &:before {
      content: '';
      display: block;
      width: 25px;
      height: 25px;
      background-image: url("/static/img/v2/components/close.svg");
      background-size: 15px;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  &__form {
    @include media-breakpoint-down(lg) {
      max-width: 50%;
      min-width: 345px;
      background-color: white;
      position: relative;
      z-index: 501;
      padding: 1.5rem;
      top: 40px;
      height: min-content;
      max-height: 90vh;
      overflow-y: scroll;
    }
    @include media-breakpoint-up(lg) {
      display: block;
    }
  }
  &__responsive {
    display: block;
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
  &__category {
    font: {
      size: calculate-rem(16);
      weight: 600;
    }
    justify-content: space-between;
    align-items: center;
    display: flex;
    @include media-breakpoint-down(md) {
      padding-bottom: 0.75rem;
      //border-bottom: 1px solid $secondary-variant;
    }
  }
  &__categories {
    &--hide {
      display: none;
    }
    @include media-breakpoint-up(lg) {
      display: block;
    }
  }
  &__button {
    margin-right: calculate-rem(8);
  }
  &__list {
    display: block;
  }
  &__item {
    display: flex;
    margin-bottom: calculate-rem(4);
    align-items: flex-start;
    &--secondary, &--tertiary {
      @extend .filters__item;
    }
    &--secondary {
      margin-left: 28px;
    }
    &--tertiary {
      margin-left: 56px;
    }
  }
  &__label {
    order: 2;
    margin-bottom: 0;
    margin-left: 1rem;
    user-select: none;
    font-size: calculate-rem(14);
  }
  &__reset {
    font: {
      //family: $font-secondary;
      weight: 300;
      size: calculate-rem(16);
    }
    text-decoration: underline;
    border: none;
    outline: none;
    color: $tertiary;
    padding: 0;
  }
  &__submit {
    display: none;
  }
}
