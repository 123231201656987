// Custom scrollbar
// -----------------------------
*::-webkit-scrollbar {
  width: $scroll-bar-width;
}

*::-webkit-scrollbar-track {
  background-color: $scroll-bar-track;
}

*::-webkit-scrollbar-thumb {
  background-color: $scroll-bar-thumb;
}