.responsive-nav {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  z-index: 50;
  box-sizing: border-box;
  flex-flow: column;
  background-color: $primary;
  top: 0;
  left: 0;
  overflow-y: auto;
  @include media-breakpoint-up(sm) {
    padding: 0 50px;
  }
  @include media-breakpoint-up(xl) {
    display: none;
  }
  @if($type-of-responsive-nav == 1 OR $type-of-responsive-nav == 2) {
    visibility: hidden;
    transform: translateX(100%);
    transition-property: visibility, transform;
    transition-timing-function: cubic-bezier(.455,.03,.515,.955), cubic-bezier(.25,.46,.45,.94);
    transition-duration: 300ms;
    transition-delay: 2s, #{400+(100*$menuItems-responsive)}ms;
  }
  @else if($type-of-responsive-nav == 3) {
    visibility: hidden;
    opacity: 0;
    transition-property: visibility, opacity;
    transition-timing-function: cubic-bezier(.455,.03,.515,.955), cubic-bezier(.25,.46,.45,.94);
    transition-duration: 300ms;
    transition-delay: 2s, #{400+(100*$menuItems-responsive)}ms;
  }
  &__list {
    display: flex;
    padding-top: 40px;
    list-style: none;
    flex: 1;
    @if($language-top) {
      order: 2;
    }
    @else {
      order: 1;
    }

    @if($type-of-responsive-nav == 1 OR $type-of-responsive-nav == 3) {
      flex-flow: column;
    }
    @else if($type-of-responsive-nav == 2) {
      flex-flow: row;
    }

    @if($languages == false) {
      margin-top: 70px;
      padding-top: 0;
    }

    @media all and (max-width: 700px) and (max-height: 400px) {
      flex-flow: row;
      align-items: center;
      justify-content: center;
      padding: 10px 0 0;
    }
  }
  &__item {
    opacity: 0;
    @if($type-of-responsive-nav == 1 OR $type-of-responsive-nav == 2) {
      transform: translateY(50px);
    }
    @else if($type-of-responsive-nav == 3) {
      transform: translateX(50px);
    }
    transition-property: opacity, transform;
    transition-duration: .5s, .6s;
    transition-timing-function: cubic-bezier(.25,.46,.45,.94), cubic-bezier(.55,.085,.68,.53);
    @for $i from 1 through ($menuItems-responsive) {
      &:nth-child(#{$i}) {
        transition-delay: #{((($menuItems-responsive+1)*100)+300) - (100*$i)}ms;
      }
    }
  }
  &__link {
    font-size: calculate-rem(30);
    font-weight: 600;
    color: white;
    &:hover {
      color: white;
    }
  }
  &__dropdown {
    padding-left: 10px;
    &-link {
      font-size: calculate-rem(18);
      color: white;
      line-height: 1.7;
      &:hover {
        color: white;
      }
    }
  }
  &__social-network-link {
    display: block;
    color: white;
    transform: translateY(100%);
    opacity: 1;
    transition-property: transform, opacity;
    transition-duration: .3s;
    transition-timing-function: ease-in;
    transition-delay: #{($menuItems*75) + 100}ms;
    line-height: normal;
    font-size: calculate-rem(16);
    &--variant {
      @extend .responsive-nav__social-network-link;
      flex: 2;
    }
  }
}

.header--responsive {
  .responsive-nav {
    @if($type-of-responsive-nav == 1 OR $type-of-responsive-nav == 2) {
      visibility: visible;
      transform: translateX(0);
    }
    @else if($type-of-responsive-nav == 3) {
      visibility: visible;
      opacity: 1;
    }
    transition-delay: 0s;
    &__item {
      opacity: 1;
      @if($type-of-responsive-nav == 1 OR $type-of-responsive-nav == 2) {
        transform: translateY(0);
      }
      @else if($type-of-responsive-nav == 3) {
        transform: translateX(0);
      }
      transition-property: opacity, transform;
      transition-duration: .3s, .5s;
      transition-timing-function: cubic-bezier(.25,.46,.45,.94), cubic-bezier(.175,.885,.32,1.275);
      @for $i from 1 through ($menuItems-responsive) {
        &:nth-child(#{$i}) {
          transition-delay: 300ms+(100ms*($i));
        }
      }
    }
  }
  .responsive-languages__list {
    @if($type-of-responsive-nav == 1 OR $type-of-responsive-nav == 2) {
      transform: translateY(0);
    }
    @else if($type-of-responsive-nav == 3) {
      transform: translateX(0);
    }
    opacity: 1;
    transition-property: transform, opacity;
    transition-duration: .2s;
    transition-timing-function: ease-in;
    @if($language-top == false) {
      transition-delay: #{((($menuItems-responsive+1)*100)+300)}ms;
    }
    @else {
      transition-delay: 300ms;
    }
  }
  .responsive-nav__social-network-link, .responsive-nav__social-network-link--variant {
    transform: translateY(0%);
    opacity: 1;
    transition-property: transform;
    transition-duration: .3s;
    transition-timing-function: ease-in;
    transition-delay: #{($menuItems * 100) + 100}ms;
    @include media-breakpoint-down(md) {
      opacity: 1;
    }
    &:hover {
      opacity: 1;
      color: white;
    }
  }
}