.logo {
  display: flex;
  align-items: center;
  &__link {
    display: block;
    position: relative;
    height: auto;
    width: 230px;
  }
  &__image {
    max-width: 100%;
  }
}