input[type="radio"] {
  appearance: none;
  border: 1px solid #C4C4C4;
  width: 21px;
  min-width: 21px;
  height: 21px;
  min-height: 21px;
  border-radius: 21px;
  display: block;
  position: relative;
  &:checked {
    &:after {
      content: '';
      display: block;
      width: 13px;
      height: 13px;
      position: absolute;
      top: 3px;
      left: 3px;
      background-color: $secondary;
      border-radius: 13px;
    }
  }
}