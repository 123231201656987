.notifications {
    @extend .fs-14;
    max-width: fit-content;
    &__error {
        color: $secondary;
    }
}

.flashes {
    z-index: 500;
}