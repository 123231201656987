@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin font-face($style-name, $file, $weight, $style: normal) {
  $filepath: '../../fonts/' + $file;
  @font-face {
    font-family: $style-name;
    src: url($filepath + ".ttf") format('truetype'), url($filepath + ".woff") format('woff');
    font-weight: $weight;
    font-style: $style;
  }
}