* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  min-height: 100%;
  font-size: 100%;
  line-height: 1.5;

  //@each $breakpoint in map-keys($grid-breakpoints) {
  //  @include media-breakpoint-only($breakpoint) {
  //    font-size: map-get($font-size-delta, $breakpoint);
  //  }
  //}
}

body {
  background-color: $background-color;
  color: $font-color;
  margin: 0;
  min-height: 100%;
  position: relative;

  font: {
    family: $font;
    size: $font-size;
    weight: 400;
  }

  //// TODO - have to check
  padding-top: #{($padding-menu)+$menu-size-height-responsive}px;
  @include media-breakpoint-up(lg) {
    padding-top: #{($padding-menu*2)+$menu-size-height}px;
  }
}

.block-spacing {
  @extend .pb-10;
  &:first-of-type {
    @extend .pt-10;
  }
}

.img-responsive {
  min-width: 100%;
  &-max-width {
    max-width: 100%;
  }
}

::selection{ color:$background-color; background:$font-color; }

// Used when click on burger icon
// -----------------------------
.no-scroll {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}

.fw-medium {
  font-weight: 500!important;
}

.gap {
  &-20 {
    gap: 20px !important;
  }
}