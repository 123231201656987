.faq {
  &__content {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 31px;
      height: 31px;
      border-radius: 20px;
      background-color: white;
      background-image: url("/static/img/v2/components/carret.svg");
      background-size: 13px 8px;
      background-position: center;
      background-repeat: no-repeat;
      right: 15px;
      top: 11px;
    }
    p {
      padding: 0 26px;
    }
    &--disabled {
      @extend .faq__content;
      p {
        display: none;
      }
    }
  }
  &__title {
    border: 1px solid rgba(12, 0, 55, 0.5);
    border-radius: 37px;
    padding: 17px 26px;
  }
}