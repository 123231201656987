.step {
  position: relative;
  width: 467px;
  padding-left: 40px;
  &:before {
    content: '';
    display: block;
    width: calc(100% - 40px);
    height: 2px;
    background-color: $primary;
    position: absolute;
    top: 19px;
    left: 40px;
  }
  &__number {
    width: 38px;
    height: 38px;
    color: $primary;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 20px;
    font-weight: bold;
    z-index: 2;
    &--active {
      color: white;
      &:after {
        background-color: $primary;
      }
    }
    &-span {
      position: relative;
      z-index: 2;
    }
    &:before {
      content: '';
      display: block;
      width: 66px;
      height: 38px;
      background-color: white;
      position: absolute;
      z-index: 0;
      top: -1px;
      left: 50%;
      transform: translateX(-50%);
    }
    &:after {
      content: '';
      display: block;
      width: 38px;
      height: 38px;
      border: 1px solid $primary;
      border-radius: 38px;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
    }
  }
  &__text {
    font-family: $font-secondary;
    font-size: calculate-rem(14);
    position: absolute;
    left: 50%;
    top: 58px;
    transform: translate(-50%, 0);
    white-space: nowrap;
  }
}