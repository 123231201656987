.table {
  & td, & th {
    border-bottom: 1px solid #ECECEC;
  }
  & thead {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  & tbody, & thead {
    border: 0 !important;
  }
  & tr {
    opacity: 1;
    transition-property: opacity;
    transition-duration: 500ms;
    @include media-breakpoint-down(md) {
      display: block;
      height: auto;
      margin-bottom: 30px;
    }
  }
  &__row {
    .add-list__form {
      display: none;
    }
    &--active {
      background-color: #F8F8F8;
      .add-list__form {
        display: block;
      }
    }
  }
  & td {
    vertical-align: middle !important;
    position: relative;
    @include media-breakpoint-down(md) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 55px;
      & .table__icon {
        justify-content: center;
      }
      &:before {
        content: attr(data-label);
      }
    }
  }
  &__button {
    appearance: none;
    border: none;
    display: block;
    &-text {

    }
  }
}