.pricing {
  background-image: url('/static/img/v2/pages/pricing.svg');
  background-position: center left;
  background-repeat: no-repeat;
  @include media-breakpoint-down(lg) {
      background: none;
  }
  &__g-col {
    @include media-breakpoint-down(lg) {
      grid-column: auto/span 12 !important;
    }
  }
  &__container {
    width: 100%;
    @include media-breakpoint-up(lg) {

    }
  }
  &__item {
    display: flex;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    padding: 5px 0;
    &:after {
      content: '..................................................................................................................................................................................................................';
      display: block;
      position: absolute;
    }
  }
  &__elem {
    background-color: $light;
    position: relative;
    display: block;
    padding: 2px 5px;
    z-index: 5;
  }
  &__bg-grey {
    background-color: #F8F8FF;
  }
  &__bg-pink {
    background-color: #F9E6E6;
    &-50 {
      background-color: rgba(#F9E6E6, 0.5);
    }
  }
  &__th-border {
    border-color: white;
    &-left {
      @extend .pricing__th-border;
      border-left-width: 9px;
    }
    &-right {
      @extend .pricing__th-border;
      border-right-width: 9px;
    }
  }
}