.login {
  height: calc(100vh - 118px);
  min-height: 650px;
  &__container {
    display: flex;
  }
  &__background {
    background-color: #F8F8FF;
  }
  &__logo {
    width: 164px;
    @include media-breakpoint-down(sm) {
      width: 100px;
    }
  }
  &__image {
    width: 300px;
  }
  &__form-fieldset {
    max-width: 350px;
  }
  &__message {
    &-title {
      font-size: calculate-rem(22);
      font-weight: 700;
      color: $secondary;
    }
    &-paragraph {
      font-size: calculate-rem(16);
      color: $secondary;
      margin: 0;
    }
    &--danger {
      padding: 24px;
      background-color: #F9E6E6;
      border-radius: 12px;
    }
  }
}