@charset "UTF-8";

@import "basics/bootstrap-variables";
@import '../../../node_modules/bootstrap/scss/bootstrap';
@import "basics/index";
@import "atoms/index";
@import "components/index";

@import "pages/index";

.border-primary-alpha {
  border-color: rgba(12, 0, 55, 0.1);
}