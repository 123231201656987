@each $title in map-keys($titles) {
  $title-config: map-get($titles, $title);
  .title-#{$title} {
    font-size: calculate-rem(nth($title-config, 3));
    line-height: calculate-rem(nth($title-config, 4));
    @include media-breakpoint-up(lg) {
      font-size: calculate-rem(nth($title-config, 1));
      line-height: calculate-rem(nth($title-config, 2));
    }
    margin: 0;
    font-weight: nth($title-config, 5);
    font-family: $font-secondary;
  }
}

.title-faq {
  position: relative;
  &:before {
    @include media-breakpoint-up(lg) {
      display: block;
      content: '';
      position: absolute;
      top: -20px;
      left: 50%;
      background-color: #FFEBEB;
      width: 462px;
      height: 44px;
      border-radius: 8px;
      transform: rotate(-2.54deg) skewY(-3.22deg) translateX(-50%);
      z-index: -1;
    }
  }
}

.title-element {
  position: relative;
  &-span {
    position: relative;
    z-index: 1;
  }
  &:before {
    @include media-breakpoint-up(lg) {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background-color: #F0F2FF;
      width: 292px;
      height: 44px;
      border-radius: 8px;
      transform: rotate(-2.54deg) skewY(-3.22deg);
      z-index: 0;
    }
  }
  &:after {
    @include media-breakpoint-up(lg) {
      display: block;
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: #F0F2FF;
      width: 351px;
      height: 44px;
      border-radius: 8px;
      transform: rotate(-2.54deg) skewY(-3.22deg);
      z-index: 0;
    }
  }
}

.title-front {
  font-family: $font-secondary;
  &-1 {
    @extend .title-front;
    font-size: calculate-rem(44);
    font-weight: 500;
    line-height: calculate-rem(50);
  }
  &-2 {
    @extend .title-front;
    font-size: calculate-rem(26);
    font-weight: 500;
    line-height: calculate-rem(30);
  }
}