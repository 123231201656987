.documents {
  margin-bottom: 120px;
  position: relative;
  @extend %interBlockSpace;
  &__h1 {
    @extend %title1;
  }
  &__addFile {
    position: absolute;
    top: 0;
    right: 0;
    background-image: url("/static/img/v2/components/plus--white.svg");
    background-repeat: no-repeat;
    background-position: 1em center;
    padding-left: 2em;
    @include media-breakpoint-down(xs) {
      position: relative;
      left: 0;
      margin-bottom: 30px;
    }
  }
  &____addList {
    text-align: center;
  }
  &__link {
    text-decoration: underline;
  }
}