input[type="checkbox"] {
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  border: 1px solid #999999;
  background-color: white;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 3px;
  outline: none;
  &:checked {
    background-image: url("/static/img/v2/components/checked.svg");
    background-color: $secondary;
    border-color: $secondary;
  }
  &:focus {
    border-color: $secondary;
    box-shadow: none;
  }
}