.settings {
  position: relative;
  max-width: fit-content;
  &__name {
    color: white;
    font: {
      size: calculate-rem(14);
      weight: 500;
    }
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    white-space: nowrap;
    z-index: 1;
    &:after {
      content: '';
      width: 4px;
      height: 4px;
      border-right: 1px solid white;
      border-bottom: 1px solid white;
      transform: rotate(45deg);
      margin-left: 8px;
    }
  }
  &__list {
    background-color: $light;
    border-radius: 6px;
    padding: 13px 22px;
    position: absolute;
    left: 50%;
    top: 50px;
    z-index: 0;
    opacity: 0;
    transform: translate(-50%, -200%);
    font-size: calculate-rem(14);
    line-height: calculate-rem(25);
    transition-property: transform, opacity;
    transition-duration: 300ms;
    transition-delay: 300ms, 0ms;
    list-style: none;
    width: fit-content;
  }
  &__item {
    white-space: nowrap;
    &:last-of-type {
      padding-top: 8px;
      margin-top: 8px;
      border-top: 1px solid #DADCE0;
    }
  }
  &--active {
    .settings__list {
      opacity: 1;
      transform: translate(-50%,0%);
      transition-property: transform, opacity;
      transition-duration: 300ms;
      transition-delay: 0ms, 150ms;
    }
  }
}