// Bootstrap variables
// -----------------------------
$primary: #0C0037;
$secondary: #FF4D3D;
$light: #F8F8FF;

$utilities: (
        "opacity": (
                property: opacity,
                values: (
                        0: 0,
                        25: .25,
                        50: .5,
                        70: .7,
                        75: .75,
                        100: 1,
                )
        ),
        "cursor": (
          property: cursor,
          values: (
            p: "pointer"
          )
        ),
        "border-color": (
            property: border-color,
            class: border,
            values: ("grey-40": #CCCCCC,"grey-50": #ECECEC, "primary": $primary, "secondary": $secondary, "light": $light)
        ),
        "rounded": (
          property: border-radius,
          class: rounded,
          values: ("0": 0, "1": 6px, "2": 12px, "3": 24px, "100": 100px)
        ),
        "font-size": (
          property: font-size,
          class: "fs",
          values: (
            10: 0.625rem,
            11: 0.687rem,
            12: 0.75rem,
            13: 0.812rem,
            14: 0.875rem,
            16: 1rem,
            18: 1.125rem,
            20: 1.25rem,
            22: 1.375rem,
            24: 1.5rem,
            30: 1.875rem
          )
        ),
        "width": (
          property: width,
          class: w,
          responsive: true,
          values: (
            20: 20%,
            25: 25%,
            40: 40%,
            50: 50%,
            75: 75%,
            100: 100%,
            max-content: max-content,
            auto: auto
          )
        ),
        "height": (
                property: height,
                class: h,
                responsive: true,
                values: (
                        25: 25%,
                        50: 50%,
                        75: 75%,
                        100: 100%,
                        max-content: max-content,
                        auto: auto,
                        100vh: 100vh
                )
        ),
        "font-weight": (
                property: font-weight,
                class: fw,
                values: (
                        lighter: 200,
                        light: 300,
                        normal: 500,
                        semibold: 600,
                        bold: 700,
                        bolder: 800,
                )
        ),
);

$grid-breakpoints: (
        xs: 0,
        sm: 565px,
        md: 768px,
        lg: 1024px,
        xl: 1200px,
        xxl: 1400px
);

$container-max-widths: (
        sm: 565px,
        md: 708px,
        lg: 924px,
        xl: 1140px,
        xxl: 1320px
);

$spacers: (
        0: 0,
        1: 0.25rem,
        2: 0.5rem,
        3: 0.75rem,
        4: 1rem,
        5: 1.5rem,
        6: 2rem,
        7: 3.5rem,
        8: 4.5rem,
        9: 6.5rem,
        10: 8rem,
        11: 9rem,
);

$enable-cssgrid: true;
$toast-border-color: rgba(12, 0, 55, 0.5)